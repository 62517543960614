import React from 'react'
import { Header } from '../../components/header/header'
import { Sections } from '../../components/sections/sections'

const homeStyle = {}

export const Home = () => {
  return (
    <div style={homeStyle}>
      <Header showVideo={false} />
      {/* Pass the required props */}
      <Sections />
    </div>
  )
}
