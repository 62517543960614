import { StoreHeader } from '../../components/header/storeHeader'
import Title from '../../components/title/title'
import { SketchfabAPI } from '../../components/sections/sketchfab/sketchfabAPI'
import { InnerFooter } from '../../components/footer/innerFooter'
import '../../App.css'

import headerImage from './Header_Asset_Collection_Final.jpg'

function Store() {
  const title = 'Asset Gallery'
  const subtitle = 'STAR'

  return (
    <div>
      <div className='max-h-[405px] w-full overflow-hidden relative storeHeader'>
        <StoreHeader showVideo={false} headerImage={headerImage}>
          <img src='' alt='Header Image' className='h-full w-full object-cover storeHeaderImage' />
        </StoreHeader>
        <Title title={title} subtitle={subtitle}></Title>
      </div>
      <SketchfabAPI id='store' />
      <InnerFooter />
    </div>
  )
}

export default Store
